import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import {
  Background,
  ModalWrapper,
  Head,
  IconContainer,
  ButtonContainer,
  StyledForm,
  ScrollContainer,
  RowContainer,
} from "./Styles";

/* Iconos */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

/* Componentes */
import ButtonPrimary from "../../ButtonPrimary/ButtonPrimary";

/* Dependencias */
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { editarDestinatariosMails } from "../../../Services";

/* Validación de inputs con Formik */
const validationSchema = yup.object({
  destinatarios: yup.array().of(
    yup.object({
      mail: yup
        .string()
        .email("Debe ser un correo válido")
        .required("El correo es obligatorio"),
      name: yup.string().required("El nombre es obligatorio"),
    })
  ),
});

export const ModalModificarDestinatarios = ({ onClose }) => {
  const dispatch = useDispatch();
  const { destinatarios } = useSelector((state) => state.destinatariosMails);

  const [rows, setRows] = useState(destinatarios);

  const formik = useFormik({
    initialValues: {
      destinatarios: destinatarios,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(editarDestinatariosMails(values.destinatarios));
      onClose();
    },
  });

  const handleEditRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
    formik.setFieldValue("destinatarios", updatedRows);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    formik.setFieldValue("destinatarios", updatedRows);
  };

  const handleAddRow = () => {
    const maxId = rows.reduce((max, row) => (row.id > max ? row.id : max), 0);
    const newRow = { id: maxId + 1, name: "", mail: "" };
    const updatedRows = [...rows, newRow];
    setRows(updatedRows);
    formik.setFieldValue("destinatarios", updatedRows);
  };

  useEffect(() => {
    formik.setFieldValue("destinatarios", rows);
  }, [rows]);

  const handleClose = () => {
    onClose();
  };

  return ReactDOM.createPortal(
    <Background>
      <ModalWrapper onClose={handleClose}>
        <Head>
          <p>Modificar lista de destinatarios</p>
          <IconContainer>
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </IconContainer>
        </Head>
        <StyledForm onSubmit={formik.handleSubmit}>
          <ScrollContainer>
            {rows.map((row, index) => (
              <RowContainer key={index}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={row.name}
                  onChange={(e) => handleEditRow(index, "name", e.target.value)}
                  error={Boolean(formik.errors.destinatarios?.[index]?.name)}
                  helperText={formik.errors.destinatarios?.[index]?.name}
                  style={{ marginRight: "1rem" }}
                />
                <TextField
                  fullWidth
                  label="Correo"
                  value={row.mail}
                  onChange={(e) => handleEditRow(index, "mail", e.target.value)}
                  error={Boolean(formik.errors.destinatarios?.[index]?.mail)}
                  helperText={formik.errors.destinatarios?.[index]?.mail}
                  style={{ marginRight: "1rem" }}
                />
                <div className="icons">
                  <IconButton
                    color="primary"
                    onClick={() => handleEditRow(index)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </RowContainer>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddRow}
              style={{ margin: "1rem auto", display: "block" }}
            >
              Agregar
            </Button>
          </ScrollContainer>
          <ButtonContainer>
            <ButtonPrimary
              type="button"
              textArea="Cancelar"
              onClick={handleClose}
              variant="outlined"
              color="primary"
            />
            <ButtonPrimary
              type="submit"
              textArea="Guardar cambios"
              variant="contained"
              color="primary"
            />
          </ButtonContainer>
        </StyledForm>
      </ModalWrapper>
    </Background>,
    document.getElementById("root")
  );
};
