import styled from "styled-components";
import { FormControl, Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
`;

export const ModalWrapper = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e5e5e5;
  box-shadow: 0rem 1.25rem 2.5rem -0.75rem rgba(196, 196, 196, 0.35);
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  width: 50rem; /* Incrementamos el ancho */
  max-height: 80vh; /* Limita la altura máxima del modal */
  overflow-y: auto; /* Habilita el scroll vertical */
  z-index: 10;

  /* Ajustes responsivos */
  @media (max-width: 768px) {
    width: 90%;
    padding: 1rem;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75rem;
  & p {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  & svg {
    font-size: 1.25rem; /* Tamaño reducido de los íconos */
    color: #4f4184;
  }
`;

export const FormGroup = styled(FormControl)`
  width: 95%;
  height: 4.375rem;
  margin: 0 10px;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.span`
  font-weight: 600;
  margin: 5px 0;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: ${(props) => (props.justify ? "flex-start" : "flex-end")};
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Centra los botones */
  align-items: center;
  width: 100%; /* Se ajusta al ancho total */
  gap: 1rem; /* Espaciado entre botones */
  margin-top: 1.5rem;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #e5e5e5;

  & .name-email {
    display: flex;
    flex-direction: column; /* Ordena nombre y email en columnas */
    text-align: left; /* Alineación a la izquierda */
    flex: 1; /* Ocupa el espacio disponible */

    & .name {
      font-weight: bold;
      margin-bottom: 0.25rem; /* Espaciado entre nombre y email */
    }

    & .email {
      color: #6c757d; /* Color gris para diferenciar el email */
    }
  }
      & .icons {
    display: flex; /* Alinea íconos en fila */
    gap: 0.5rem; /* Espaciado entre los íconos */
    align-items: center;

  & .icon-button {
    font-size: 1rem; /* Tamaño reducido de los íconos */
    margin: 0 0.5rem;
    cursor: pointer;
    color: #4f4184;
  }
`;

export const ScrollContainer = styled.div`
  max-height: 50vh; /* Limita la altura del listado */
  overflow-y: auto; /* Scroll para el contenido */
  margin-bottom: 1rem;
`;
